<template>
  <b-overlay :show="loading">
    <b-card>
      <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              :label="$t('BrandSetting.password.oldPassLabel')"
              label-for="account-old-password"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  :placeholder="$t('BrandSetting.password.oldPassPlaceholder')"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="$t('BrandSetting.password.newPassLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-new-password"
                  v-model="newPasswordValue"
                  :type="passwordFieldTypeNew"
                  name="new-password"
                  :placeholder="$t('BrandSetting.password.newPassPlaceholder')"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('BrandSetting.password.retypeNewPassLabel')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  name="retype-password"
                  :placeholder="$t('BrandSetting.password.retypeNewPassPlaceholder')"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-if="canSendNewPassword"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="changePassword"
            >
              {{ $t('BrandSetting.password.save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              {{ $t('BrandSetting.password.reset') }}
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BOverlay, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BOverlay,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    canSendNewPassword() {
      return this.passwordValueOld && this.newPasswordValue && this.newPasswordValue.length && this.newPasswordValue === this.RetypePassword
    },
  },
  methods: {
    changePassword() {
      this.loading = true
      try {
        const payload = { oldPassword: this.passwordValueOld, password: this.newPasswordValue }
        this.$store.dispatch('accountSetting/updateUserPassword', payload)
        this.$toast({
          position: 'top-right',
          component: ToastificationContent,
          props: this.$t('accountSettings.accountSettingPassword.methods.changePassword.then.props'),
        })
      } catch (e) {
        this.$toast({
          position: 'top-right',
          component: ToastificationContent,
          props: this.$t('accountSettings.accountSettingPassword.methods.changePassword.catch.props'),
        })
      } finally {
        this.passwordValueOld = null
        this.newPasswordValue = null
        this.RetypePassword = null
        this.loading = false
      }
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
