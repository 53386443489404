<template>
  <div>
    <b-alert
      :variant="alertData.variant"
      :show="dismissCountDown"
      dismissible
      fade
      class="mb-1"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span><strong>{{ alertData.strongText }}</strong> {{ alertData.lightText }}</span>
      </div>
    </b-alert>
    <b-overlay :show="saving">
      <validation-observer
        ref="form"
        #default="{invalid}"
      >
        <form @submit.prevent="onSubmit">
          <b-card
            footer-class="text-muted"
            body-class="p-0"
          >
            <b-tabs
              v-model="tabIndex"
              pills
              nav-class="mb-3 pt-2 pr-2 pl-2"
            >
              <b-tab
                button-id="general"
              >
                <div slot="title">
                  <feather-icon
                    class="mr-1"
                    icon="UserIcon"
                  />{{ $t('BrandSetting.general.title') }}
                </div>

                <b-row class="p-2">
                  <b-col
                    sm="6"
                    md="6"
                    lg="6"
                  >
                    <b-form-group
                      :label="$t('BrandSetting.general.name')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('BrandSetting.general.name')"
                        vid="name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.name"
                          name="name"
                          :placeholder="$t('BrandSetting.general.namePlaceholder')"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab
                button-id="password"
              >
                <div slot="title">
                  <feather-icon
                    class="mr-1"
                    icon="SettingsIcon"
                  />{{ $t('BrandSetting.password.title') }}
                </div>
                <account-setting-password />
              </b-tab>
            </b-tabs>

            <div
              v-if="tabIndex !== 1"
              slot="footer"
              style="display: flex; justify-content: space-between;"
            >
              <div>
                <b-button
                  id="save-form"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="invalid || saving"
                >
                  <b-spinner
                    v-if="saving"
                    small
                  />
                  {{ saving ? $t('BrandSetting.saving') : $t('BrandSetting.save') }}
                </b-button>
                <b-tooltip
                  v-if="Boolean(saveButtonTooltipError)"
                  :show="Boolean(saveButtonTooltipError)"
                  target="save-form"
                  variant="danger"
                >
                  {{ saveButtonTooltipError }}
                </b-tooltip>

                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  :disabled="invalid || saving"
                  @click="goHome"
                >
                  {{ $t('BrandSetting.cancel') }}
                </b-button>
              </div>
            </div>
          </b-card>
        </form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { required, url, alphaDash } from '@validations'
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BTabs, BTab, BCard, BFormGroup, BFormInput,
  BRow, BCol, BButton,
  VBTooltip, BTooltip, BSpinner,
  BAlert, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
// import ImageInput from '../product-form/ImageInput.vue'

export default {
  // eslint-disable-next-line global-require
  whatsappImg: require('@/assets/images/icons/whatsapp.svg'),

  name: 'UserSetting',

  components: {
    BAlert,
    BOverlay,
    BTabs,
    BTab,
    BCard,
    // ImageInput,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BTooltip,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    AccountSettingPassword,
    // Cleave,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      // Alert
      dismissSecs: 4,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      // Tabs
      tabIndex: 0,
      tabIndexHashs: ['#general', '#password'],
      alertData: {
        lightText: null,
        strongText: null,
        variant: 'danger',
      },
      saving: false,
      form: {
        // general tab
        name: '',
        pictureUrl: '',
      },
      // validations
      required,
      urlValidator: url,
      alphaDash,
    }
  },
  computed: {
    saveButtonTooltipError() { return false },
  },
  watch: {
  },
  async created() {
    if (this.$route.hash && this.tabIndexHashs.includes(this.$route.hash)) {
      this.tabIndex = this.tabIndexHashs.indexOf(this.$route.hash)
    }
    await Promise.all([this.load()])
    this.previousHandle = this.form.handle
  },
  methods: {
    ...mapActions('settings', {
      loadUsers: 'getUser',
      updateUser: 'updateUser',
    }),
    goHome() { this.$router.push({ name: 'home' }) },
    showAlert(alertProps, variant) {
      this.alertData = alertProps
      this.alertData.variant = variant
      this.dismissCountDown = this.dismissSecs
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    async load() {
      const data = await this.loadUsers()
      this.form = data
    },
    async onSubmit() {
      const success = await this.$refs.form.validate()
      if (!success) { return }

      this.saving = true
      try {
        await this.updateUser({ ...this.form })
        this.showAlert(this.$t('BrandSetting.methods.onSubmit.alert'), 'success')
        this.load()
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            ...this.$t('BrandSetting.upsertdErrorToast'),
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.saving = false
      }
    },
    async save() {
      const success = await this.$refs.form.validate()
      if (!success) { return }
      this.$emit('save', this.form)
    },
    onChangePicture(pictureUrl) {
      this.$set(this.form, 'pictureUrl', pictureUrl)
    },
  },
}
</script>

<style lang="scss">
  @media (max-width: 767px){
  .small-device-inputs{
    display: block;
  }
  .nav-pills{
    display: block;
  }
  }
  .flex-description-row {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .vue2leaflet-map{
    &.leaflet-container{
      height: 250px;
    }
  }
  .inspiration-desc-text {
    font-size: 13px;
  }
  .published-msg {
    margin-top: 1px;
    font-size: 14px;
  }
</style>
